<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model
        layout="inline"
        :colon="false"
        :model="form"
        @keyup.enter.native="query"
      >
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-input
                placeholder="账号"
                v-model="form.name"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input
                placeholder="姓名"
                v-model="form.userName"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="照片" align="center">
          <template slot-scope="text">
            <div v-if="text.faceImgUrl">
              <a target="_blank" :href="text.faceImgUrl">
                <img
                  style="height: 48px; width: 48px"
                  :src="$imgCrop(text.faceImgUrl, 200, 200)"
                />
              </a>
            </div>
          </template>
        </a-table-column>
        <a-table-column title="姓名" data-index="userName"></a-table-column>
        <a-table-column title="账号" data-index="name"></a-table-column>
        <a-table-column title="手机号码" data-index="mobile"></a-table-column>
        <a-table-column
          title="更新时间"
          data-index="createAt"
          align="center"
        ></a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchList } from "@/api/setting/face";
export default {
  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      const { current, pageSize } = this;
      fetchList({
        pageNum: current,
        pageSize,
        ...this.form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.pageSize = 10;
      this.getList();
    },
  },
};
</script>

<style>
</style>